import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  modal: {
    zIndex: 100,
    position: "absolute",
    top: 40,
    right: 10,
    width: "fit-content",
    height: "20px",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  },
  paper: {
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    top: 2,
    left: 0,
  },
  dialerContainer: {
    position: "absolute",
    right: 10,
  },
}));

const WebSoftPhone = ({ onClose, isVisible, onEvent }) => {
  const classes = useStyles();
  const dialerRef = useRef(null);
  const net2PhoneDialerRef = useRef(null);

  useEffect(() => {
    if (window.Net2PhoneDialer) {
      net2PhoneDialerRef.current = new window.Net2PhoneDialer({
        rootHtmlElement: dialerRef.current,
      });

      const subscription = net2PhoneDialerRef.current.subscribe((event) => {
        console.log("Evento do dialer:", event);
        // Verifica se o evento é uma chamada de entrada que não foi atendida
        if (
          event.type === "callStateChanged" &&
          event.call.direction === "inbound"
        ) {
          onEvent();
        }
      });

      return () => {
        subscription.dispose();
        net2PhoneDialerRef.current.dispose();
      };
    } else {
      console.error("Net2PhoneDialer SDK não foi carregado.");
    }
  }, [onEvent]);

  return (
    <div
      className={classes.modal}
      style={{ display: isVisible ? "block" : "none" }}
    >
      <Paper className={classes.paper}>
        <div style={{ height: "200px", position: "relative" }}>
          <div
            id="net2phone-dialer"
            className={classes.dialerContainer}
            ref={dialerRef}
          >
            <IconButton className={classes.closeButton} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>
        </div>
      </Paper>
    </div>
  );
};

export default WebSoftPhone;
