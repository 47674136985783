import React, {useContext, useState} from "react";

import { Avatar, CardHeader } from "@material-ui/core";

import { i18n } from "../../translate/i18n";
import {AuthContext} from "../../context/Auth/AuthContext";
import Globais from "../../services/globais";

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/Add';
import SendIcon from "@material-ui/icons/Send";
import DeleteIcon from "@material-ui/icons/Delete";
import { Row, Item } from '@mui-treasury/components/flex';
import { Info, InfoTitle, InfoSubtitle } from '@mui-treasury/components/info';
import { useTutorInfoStyles } from '@mui-treasury/styles/info/tutor';
import { useSizedIconButtonStyles } from '@mui-treasury/styles/iconButton/sized';
import { useDynamicAvatarStyles } from '@mui-treasury/styles/avatar/dynamic';
import NewTicketModal from "../NewTicketGroupModal";
import api from "../../services/api";



const useStyles = makeStyles(() => ({
	action: {
		backgroundColor: '#fff',
		boxShadow: '0 1px 4px 0 rgba(0,0,0,0.12)',
		'&:hover': {
			backgroundColor: '#fff',
			color: '#000',
		},
	},
}));

let style = ''

if(document.body.offsetWidth < 600){
	style = {cursor: "pointer", marginLeft:-30}
}else{
	style = {cursor: "pointer"}
}

const TicketInfo = ({ contact, ticket, onClick, isAvatar,isMedia }) => {
	const styles = useStyles();
	const iconBtnStyles = useSizedIconButtonStyles({ padding: 6, marginLeft: 10 });
	const avatarStyles = useDynamicAvatarStyles({ radius: 12, size: 48 });
	const { user } = useContext(AuthContext);
	const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);

	const deleteGroup = async ()=>{
		try {
			const { data } = await api.post("/group/remove/" + contact?.number,{
				ticketId : ticket
			});
			console.log('Usu�rio inserido no grupo com sucesso!')
			document.getElementById('getMembers').click();
			//console.log('Medias recebidas/enviadas para o contato2:',response)

		} catch (err) {
			alert('N�o foi poss�vel excluir o contato do grupo. Erro:'+err.message);
		}
	}


	if(ticket.touserId){
		return (
			<CardHeader
				onClick={onClick}
				style={{ cursor: "pointer" }}
				titleTypographyProps={{ noWrap: true }}
				subheaderTypographyProps={{ noWrap: true }}
				avatar={<Avatar src="https://www.megatekbrasil.com.br/assets/images/avatar.png" alt="contact_image" />}
				title={`Chat Interno entre: ${Globais.usuario == ticket.user?.id? 'Voc�' : ticket.user?.name} e ${Globais.usuario == ticket.touserId? 'Voc�' :ticket?.contact?.name}`}
				subheader={
					ticket.user &&
					`${i18n.t("messagesList.header.assignedTo")} ${ticket.user?.name} ${ticket.queue ? ' | Setor: ' + ticket.queue.name : ' | Setor: Nenhum'}`
				}
			/>
		);
	}else
	if(ticket.isGroup){
		return (
			<CardHeader
				onClick={onClick}
				style={{cursor: "pointer"}}
				titleTypographyProps={{noWrap: true}}
				subheaderTypographyProps={{noWrap: true}}
				avatar={<Avatar src={contact?.profilePicUrl} alt="contact_image"/>}
				title={`Grupo: ${contact?.name} #${ticket.id}`}
				subheader={
					ticket.user &&
					`${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name} ${ticket.queue ? ' | Setor: ' + ticket.queue.name : ' | Setor: Nenhum'}`
				}
			/>
		);
	}else
	if(isAvatar) {
		return(
			<>
				<NewTicketModal
					modalOpen={newTicketModalOpen}
					onClose={(e) => setNewTicketModalOpen(false)}
					contactId={contact?.id}
				/>
				<Row p={1.5} gap={2} bgcolor={'rgb(215, 226, 217)'} borderRadius={16} margin={0.5}>
					<Item>
						<Avatar
							classes={avatarStyles}
							src={contact?.profilePicUrl}
						/>
					</Item>
					<Info position={'middle'} useStyles={useTutorInfoStyles}>
						<InfoTitle>{contact?.name}</InfoTitle>
						<InfoSubtitle>{contact?.number}</InfoSubtitle>
					</Info>
					<Item style={{marginLeft: "auto",marginRight: "0px"}} ml={1} position={'middle'}>
						{/*<IconButton  className={styles.action} classes={iconBtnStyles} onClick={() => setNewTicketModalOpen(true)}>
							<SendIcon />
						</IconButton>*/}
						{(user.profile.toUpperCase() == "ADMIN" && <IconButton  className={styles.action} classes={iconBtnStyles} onClick={() => {
								if (window.confirm('Deseja excluir o '+contact?.name+' do Grupo ?')) {
									// Excluindo membro do Grupo

									deleteGroup();
								} else {
									// Do nothing!
								}
							}}>
								<DeleteIcon />

							</IconButton>
						)}
					</Item>
				</Row>
			</>
		)
	}else
	if(isMedia) {
		return(
			<>
				<NewTicketModal
					modalOpen={newTicketModalOpen}
					onClose={(e) => setNewTicketModalOpen(false)}
					contactId={contact?.id}
				/>
				<Row p={1.5} gap={2} bgcolor={'rgb(215, 226, 217)'} borderRadius={16} margin={0.5}>
					<Item>
						<Avatar
							classes={avatarStyles}
							src={contact?.mediaUrl}
						/>
					</Item>
					<Info position={'middle'} useStyles={useTutorInfoStyles}>
						<InfoTitle>{contact?.body}</InfoTitle>
						<InfoSubtitle>{contact?.number}</InfoSubtitle>
					</Info>
					<Item style={{marginLeft: "auto",marginRight: "0px"}} ml={1} position={'middle'}>
						<IconButton  className={styles.action} classes={iconBtnStyles} onClick={() => setNewTicketModalOpen(true)}>
							<SendIcon />
						</IconButton>
					</Item>
				</Row>
			</>
		)
	}
	else{
		return (

			<CardHeader
				onClick={onClick}
				style={style}
				titleTypographyProps={{noWrap: true}}
				subheaderTypographyProps={{noWrap: true}}
				avatar={<Avatar src={contact?.profilePicUrl} alt="contact_image"/>}
				title={`${contact?.name} #${ticket.id}`}
				subheader={
					ticket.user &&
					`${i18n.t("messagesList.header.assignedTo")} ${ticket.user.name} ${ticket.queue ? ' | Setor: ' + ticket.queue.name : ' | Setor: Nenhum'}`
				}
			/>


		);
	}
};
export default TicketInfo;